import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        url: String,
    };
    static targets = [
        "result"
    ]

    private resultTarget!: HTMLElement;

    hasResultTarget!: boolean;
    hasUrlValue!: boolean;
    urlValue!: string;

    requestInProgress = false;

    connect() {

        if (!this.hasUrlValue) {
            console.error('[content-loader] You need to pass an url to fetch the remote content.');
            return;
        }
    }

    doFetch(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        this.fetchContent();
    }

    fetchContent() {
        if(this.requestInProgress) {
            return
        }

        this.requestInProgress = true;

        fetch(this.urlValue, {
            headers: new Headers({
                'x-content-loader-request': 'true'
            })
        })
            .then((response) => {
                this.requestInProgress = false;
                if (response.redirected) {
                    window.history.replaceState({}, '', response.url);
                    return { redirected: true, text: response.text() };
                }
                const eventName = response.headers.get('ksrt-trigger');
                if (eventName) {
                    window.dispatchEvent(new Event(eventName, { bubbles: true }));
                    this.dispatch(eventName, { bubbles: true });
                }
                return { redirected: false, text: response.text() };
            })
            .then((template) => {
                if (template.redirected) {
                    // @ts-ignore
                    template.text.then((t) => (document.querySelector('html').innerHTML = t));
                } else {
                    if(this.hasResultTarget) {
                        template.text.then((t) => this.resultTarget.innerHTML = t);
                    }
                }
            });
    }
}
