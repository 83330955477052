export interface Section {
  id: number;
  name: string;
  hotInTemp: number;
  coldInTemp: number;
  hotOutTemp: number;
  coldOutTemp: number;
  hotPressureDrop: number;
  coldPressureDrop: number;
  hotGapsPerPass: number;
  coldGapsPerPass: number;
  hotPasses: number;
  coldPasses: number;
  calculationOrderNumber: number;
  constructionNumber: number;
  portList: [GraphicPort];
  fluidList: [Medium];
  linkList: [GraphicLink];
  color: string;
}

export interface Medium {
  id: string;
  name: string;
  volumeFlow: number;
  temp: number;
  operatingPressure: number;
  port: GraphicPort;
  isHotSide: boolean;
}

export interface LinkElement {
  id: string;
  internalId: number;
  name: string;
  leftConnection : GraphicPort;
  rightConnection : GraphicPort;
  leftPortName : string;
  rightPortName : string;
  mediumName: string;
  temperature: number;
  volumeFlow: number;
  massFlow: number;
  pressure: number;
  sectionGroup: boolean;
  passThrough: boolean;
}

export interface GraphicPort {
  id: string;
  side: string;
  name: string;
  isHotSide: boolean;
}

export interface GraphicLink {
  fromParentId: string;
  toParentId: string;
  fromPortId: string;
  toPortId: string;
}

export enum Color {
  Red = '#FF0000',
  Blue = '#0000FF'
}
