import {Controller} from '@hotwired/stimulus';
import {
    createLeftPorts,
    createLinkElement,
    createMediumNew,
    createPort,
    createRightPorts,
    createSectionNew, fluidForeignObject,
    sectionForeignObject,
    showLinkTools
} from './multi-stage-helper';
import {Color, GraphicPort, Medium, Section} from './multi-stage-interfaces';
import {dia, layout, shapes} from '@joint/core';
import Rectangle = shapes.standard.Rectangle;
import Link = shapes.standard.Link;
import Element = dia.Element;

export default class extends Controller {
    static values = {
        interactive: Boolean
    };
    joint: any;
    namespace: any;
    graph: any;
    paper!: any;
    rect!: any;
    form!: any;
    interactiveValue!: boolean;

    async connect() {
        this.joint = await import(/* webpackChunkName: "joint-core" */ '@joint/core');
        this.form = document.forms[0];
        this.namespace = {
            shapes,
            phe: {'SectionObject': createSectionNew, 'FluidObject': createMediumNew, 'LinkObject': createLinkElement}
        };
        this.graph = new this.joint.dia.Graph({}, {cellNamespace: this.namespace});
        this.paper = this.createPaper();

        await this.initializeGraphics()

        this.paper.unfreeze();
    }

    disconnect() {
        super.disconnect();
        this.graph.clear();
        this.paper.remove();
    }

    private async initializeGraphics() {
        const initialData = await this.getInitData();

        if (initialData.graphicDataJson) {
            console.log(JSON.parse(initialData.graphicDataJson));
            this.graph.fromJSON(JSON.parse(initialData.graphicDataJson));
        } else {

            const graphicSectionData: Rectangle[] = [];
            initialData.sections.forEach((section: any) => {
                const leftPorts = createLeftPorts();
                const rightPorts = createRightPorts();
                graphicSectionData.push(createSectionNew(section, leftPorts, rightPorts, this.paper.options.height, this.paper.options.width));
            });

            const graphicLinkElementData: Rectangle[] = [];
            initialData.linkElements.forEach((linkElement: any) => {
                const linkPortLeft = createPort("F23", linkElement.portList[0].id, "left", -12);
                const linkPortRight = createPort("F24", linkElement.portList[1].id, "right", 0);
                graphicLinkElementData.push(createLinkElement(linkElement, linkPortLeft, linkPortRight))
            });

            let xVal = 100;
            let yValLinks = this.paper.options.height * 0.3 + 250;
            graphicLinkElementData.forEach((rect, index) => {
                const linkEl = initialData.linkElements[index];
                rect.prop('id', linkEl.id);
                rect.prop('internalId', linkEl.internalId);
                if (linkEl.sectionGroup) {
                    rect.prop('internalType', "sectionGroup")
                } else {
                    rect.prop('internalType', "linkElement")
                }
                rect.translate(xVal, yValLinks);
                rect.addTo(this.graph);
                xVal += 200;
            });

            xVal = 0;
            graphicSectionData.forEach((rect, index) => {
                const section = initialData.sections[index];
                rect.prop('id', section.id);
                rect.prop('internalType', "section")
                rect.translate(xVal, 0);
                section.portList.forEach((port: GraphicPort) => {
                    const color: any = port.isHotSide ? '#FF0000' : '#0000FF';
                    rect.addPort({
                        id: port.id,
                        group: port.side,
                        attrs: {
                            label: {text: port.name},
                            portBody: {fill: color}
                        }
                    });
                });
                rect.addTo(this.graph);
                xVal += 300;
            });

            xVal = 0;
            let hotXVal = 0;
            let coldXVal = 0;
            let yVal = this.paper.options.height * 0.3 + 200;
            const links: Link[] = [];
            const media: Rectangle[] = [];
            initialData.sections.forEach((section: any, sectionIndex: number) => {
                let sec: Rectangle = graphicSectionData[sectionIndex];
                if (sectionIndex > 0) {
                    // @ts-ignore
                    hotXVal = sec.get('position').x - 70;
                    // @ts-ignore
                    coldXVal = sec.get('position').x - 100;
                }

                section.fluidList.forEach((fluid: Medium) => {
                    const portLeft = createPort('', fluid.port.id, 'left', -12);
                    const portRight = createPort('', fluid.port.id, 'right', 0);
                    const medium = createMediumNew(fluid, portLeft, portRight);
                    medium.prop('sectionId', section.id);
                    const color: any = fluid.port.isHotSide ? '#FF0000' : '#0000FF';
                    medium.addPort({
                        id: fluid.port.id,
                        group: fluid.port.side,
                        attrs: {
                            label: {text: fluid.port.name},
                            portBody: {fill: color}
                        }
                    });
                    if (fluid.isHotSide) {
                        medium.prop('xAxisValue', hotXVal);
                        hotXVal += 140;
                    } else {
                        medium.prop('xAxisValue', coldXVal);
                        coldXVal += 140;
                    }


                    media.push(medium);
                });
                section.linkList.forEach((link: any) => {
                    links.push(this.addLink(link.fromParentId, link.fromPortId, link.toParentId, link.toPortId));
                });
            });

            media.forEach((rect) => {
                if (rect.attributes.id.includes('hot')) {
                    yVal = this.paper.options.height * 0.3 - 250;
                    rect.translate(rect.prop('xAxisValue'), yVal);
                } else {
                    yVal = this.paper.options.height * 0.3 + 150;
                    rect.translate(rect.prop('xAxisValue'), yVal);
                }
                rect.addTo(this.graph);
            });

            initialData.linksForSectionsAndLinkElements.forEach((link: any) => {
                links.push(this.addLink(link.fromParentId, link.fromPortId, link.toParentId, link.toPortId));
            });

            links.forEach((link) => {
                link.addTo(this.graph);
            });
        }

        this.setEventListener(this.paper);

        // IMPORT OF DATA NEEDS FIX FIRST!
        // this.graph.on('change:position', (element : any, position : any) => {
        //     console.log('Element ' + element.id + 'moved to ' + position.x + ',' + position.y);
        //     this.setGraphicData();
        // });
    }

    private async updatePheMultiStage(event: CustomEvent) {
        const sec: any = await this.getSectionDataForUpdate(event.detail.sectionId);
        this.updateSectionDataNew(sec);
    }

    // rect: shapes.standard.Rectangle, paper: dia.Paper
    private setEventListener(paper: any) {
        paper.on('element:pointerclick', (elementView: any) => {
            const currentElement = elementView.model;
            this.showData(currentElement.prop('internalType'), currentElement.prop("sectionId"), currentElement.prop("internalId"));
        });

        paper.on('element:contextmenu', (elementView: any) => {
            const currentElement = elementView.model;
            this.switchSectionPortsLeftRight(currentElement);
        });

        paper.on()

        // Register events
        paper.on('link:mouseenter', (linkView: any) => {
            showLinkTools(linkView);
        });

        paper.on('link:mouseleave', (linkView: any) => {
            linkView.removeTools();
        });
    }

    addLink(sourceId: any, sourcePortId: any, targetId: any, targetPortId: any) {

        return new this.joint.shapes.standard.Link({
            source: {
                id: sourceId,
                port: sourcePortId
            },
            target: {
                id: targetId,
                port: targetPortId
            },
        });

    }

    createPaper() {
        return new this.joint.dia.Paper({
            el: <HTMLElement>this.element,
            model: this.graph,
            width: this.element.clientWidth * 2,
            height: 1000,
            gridSize: 1,
            cellViewNamespace: this.namespace,
            restrictTranslate: true,
            linkPinning: false, // Prevent link being dropped in blank paper area
            defaultLink: () =>
                new this.joint.shapes.standard.Link({
                    attrs: {
                        wrapper: {
                            cursor: 'default'
                        }
                    }
                })
        });
    }

    private showData(type: string, sectionId: string, elementId: string) {

        let link: string;

        switch (type) {
            case "medium":
                link = "/phe/multi_stage_parameter/medium_parameters/" + sectionId + "/" + elementId;
                break;
            case "section":
                link = "/phe/multi_stage_parameter/section_thermal_parameters/" + elementId;
                break;
            case "linkElement":
                link = "/phe/multi_stage_parameter/link_element_parameters/" + elementId;
                break;
            case "sectionGroup":
                link = "/phe/multi_stage_parameter/section_group/" + elementId;
                break;
            default:
                alert("No valid graphical element");
                return;
        }

        window.dispatchEvent(new CustomEvent("show-off-canvas", {
            bubbles: true,
            detail: {href: link, position: "RIGHT", closeEventDetail: {sectionId: elementId}}
        }));
    }

    private async rebuild() {
        this.graph.clear();
        await this.initializeGraphics();
    }

    private getInitData(): any {
        return fetch('/phe/multi_stage/initialize_new', {
            method: 'GET',
            headers: {'Content-Type': 'application/json;charset=utf-8'}
        })
            .then((response) => {
                return response.json();
            })
            .then((dataObj) => {
                return dataObj;
            });
    }

    private getSectionDataForUpdate(id: bigint): any {
        return fetch('/phe/multi_stage/update_data/', {
            method: 'GET',
            headers: {'Content-Type': 'application/json;charset=utf-8'}
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                return data;
            });
    }

    private updateSectionDataNew(graphicData: any) {
        // const section: Rectangle = this.graph.getCell(sectionUpdate.id);
        // section.set("markup", sectionForeignObject(sectionUpdate));
        graphicData.sections.forEach((section: any) => {
            const sectionToUpdate: Rectangle = this.graph.getCell(section.id);
            sectionToUpdate.set("markup", sectionForeignObject(section));
            section.fluidList.forEach((fluid: any) => {
                const fluidToUpdate: Rectangle = this.graph.getCell(fluid.id);
                fluidToUpdate.set("markup", fluidForeignObject(fluid));
            });
        });
    }

    private switchSectionPortsLeftRight(mediumUpdate: Medium) {
        const medium: Rectangle = this.graph.getCell(mediumUpdate.id);
        const ports: Element.Port[] = medium.getPorts();

        for (var port of ports) {
            const portId = port.id;
            if (portId != null) {
                if (medium.portProp(portId, 'group') == 'right') {
                    medium.portProp(portId, 'group', 'left')
                } else {
                    medium.portProp(portId, 'group', 'right')
                }
            }
        }


    }

    private switchSectionPortsTopBottom(sectionUpdate: Section) {
        const section: Rectangle = this.graph.getCell(sectionUpdate.id);
        const ports: Element.Port[] = section.getPorts();

        for (const port of ports) {
            const portId = port.id;
            if (portId != null) {
                const color = section.portProp(portId, 'attrs/portBody/fill') == Color.Red ? Color.Blue : Color.Red;
                section.portProp(portId, 'attrs/portBody/fill', color)
            }
        }
    }

    private setGraphicData() {
        return fetch('/phe/multi_stage/set_graphic_data', {
            method: 'POST',
            headers: {'Content-Type': 'application/json;charset=utf-8'},
            body: JSON.stringify(this.graph.toJSON()),
        })
    }
}
