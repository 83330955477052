import {Controller} from '@hotwired/stimulus';
import morphdom from 'morphdom';

import Offcanvas from "bootstrap/js/dist/offcanvas";

/**
 * This is the controller for the off canvas panel.
 *
 * It can be triggered via a global event or via declarative attributes.
 *
 * In your javascript (e.g. click handler) you need to dispatch a window event like this:
 *
 *  window.dispatchEvent(new CustomEvent("show-off-canvas", { bubbles: true })); <-- bubbles is important, such that it can be picked up at the root level of the html!
 *
 *  In case you want to show the off canvas via attribute you can do it like this (similar to what we have for modal dialogs)
 *
 *  <a data-action="off-canvas#show" data-off-canvas-position-param="BOTTOM|RIGHT" data-off-canvas-href-param="..." (or href="...") data-off-canvas-method-param="GET|POST"
 *
 *  This will open the canvas at the given location (bottom or right, the default is right) and load the content from the
 *  defined url and morph it into the body of the off canvas element.
 *
 *  If you need to trigger it via event you can provide settings via additional attributes in the event, e.g.
 *
 *  window.dispatchEvent(new CustomEvent("show-off-canvas", { bubbles: true,  detail: { canvasPosition: "BOTTOM", href: "...", method: "GET|POST"} }));
 *
 */
export default class extends Controller {
    static targets = ['canvasRight', 'canvasBottom'];

    private canvasRightTarget!: HTMLDivElement;
    private canvasBottomTarget!: HTMLDivElement;
    private isOpen = false;
    private position!: string;

    private spinner: string = `
         <div class="sk-chase-em" style="width: 50px; height: 50px">
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
        </div>`

    // Show via declarative attributes
    async show(event: Event) {
        event.preventDefault();
        const currentTarget = <HTMLElement>event.currentTarget;
        const method = currentTarget.dataset.offCanvasMethodParam ? currentTarget.dataset.offCanvasMethodParam : 'GET';
        const position = currentTarget.dataset.offCanvasPositionParam ? currentTarget.dataset.offCanvasPositionParam : 'RIGHT';
        const templateUrl = (<HTMLLinkElement>event.currentTarget).href || (<HTMLButtonElement>event.currentTarget).dataset.offCanvasHrefParam;
        this.position = position;

        if (this.isOpen) {
            return;
        }
        const offCanvas = this.openCanvas(position);
        await this.doShow(method, position, templateUrl, offCanvas, {});
    }

    // show via window event
    async showCanvas(event: CustomEvent) {
        event.preventDefault();

        const method = event.detail.method || 'GET';
        const position = event.detail.position || 'RIGHT';
        const templateUrl = event.detail.href;
        this.position = position;
        const closeEventDetail = event.detail.closeEventDetail || {};

        if (this.isOpen) {
            return;
        }
        const offCanvas = this.openCanvas(position);
        await this.doShow(method, position, templateUrl, offCanvas, closeEventDetail);
    }

    openCanvas(position: string): Offcanvas {
        let offCanvas;
        if (position == 'BOTTOM') {
            offCanvas = new Offcanvas(this.canvasBottomTarget, {backdrop: "static"});
        } else {
            offCanvas = new Offcanvas(this.canvasRightTarget, {backdrop: "static"});
        }

        offCanvas.show();
        this.isOpen = true;

        return offCanvas;
    }

    enlarge() {

        if (this.position == 'RIGHT') {
            const maxWidth = window.innerWidth;

            this.canvasRightTarget.style.transition = 'width 1s';
            this.canvasRightTarget.style.width = (maxWidth - (0.25 * maxWidth)) + 'px';

            window.setTimeout(() => {
                this.canvasRightTarget.style.transition = '';
            }, 1100)


        } else {
           // TODO, maybe we don't need it now
        }
    }

    shrink() {
        if (this.position == 'RIGHT'){
            this.canvasRightTarget.style.transition = 'width 1s';
            this.canvasRightTarget.style.width = '600px';

            window.setTimeout(() => {
                this.canvasRightTarget.style.transition = '';
            }, 1100)
        }
    }


    async doShow(method: string, position: string, templateUrl: string | undefined, offCanvas: Offcanvas, closeEventDetail : Object) {
        let firstChild: HTMLElement;
        let canvasTarget;
        if (position == 'BOTTOM') {
            canvasTarget = this.canvasBottomTarget;
            firstChild = <HTMLElement>this.canvasBottomTarget.firstElementChild;
        } else {
            canvasTarget = this.canvasRightTarget;
            firstChild = <HTMLElement>this.canvasRightTarget.firstElementChild;
        }

        if (templateUrl && firstChild) {
            const content = await fetch(templateUrl, {method: method}).then((response) => response.text());
            firstChild.innerHTML = content;
            firstChild.style.margin = '0px';
            firstChild.style.overflowY = 'scroll'
            firstChild.style.overflowX = 'hidden';

            canvasTarget.addEventListener('hidden.bs.offcanvas', () => {
                firstChild.innerHTML = this.spinner;
                firstChild.style.margin = 'auto';
                firstChild.style.overflowY = 'hidden';
                firstChild.style.overflowX = 'hidden';
                canvasTarget.style.transition = '';
                canvasTarget.style.width = 600 + "px";
                this.isOpen = false;
                this.dispatch('off-canvas-hidden', {});
                window.dispatchEvent(new CustomEvent('off-canvas-hidden', {detail : closeEventDetail}));
            });

        } else {
            offCanvas.hide();
            this.isOpen = false;
            console.log("Invalid off canvas configuration")
        }
    }
}
