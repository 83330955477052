import 'bootstrap';

import 'fast-text-encoding';
import './vendor/html-events';
import './components/pdf-quote';
import './components/search-product-select';
import './components/validator';
import './components/validator-product-search';

import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';

// @ts-expect-error no type definitions exist
import { Autocomplete } from 'stimulus-autocomplete';

import multi_stage from './jointjs/multi-stage-controller';
import multi_stage_new from './jointjs/multi-stage-new-controller';

// @ts-expect-error we create a global array for a library we use
document.jsuitesComponents = [];

const application = Application.start();
const context = require.context('./controllers', true, /\.ts$/);
application.load(definitionsFromContext(context));
application.register('autocomplete', Autocomplete);
application.register('multi-stage', multi_stage);
application.register('multi-stage-new', multi_stage_new);

const isDebugMode = document.querySelector('meta[name="isDebugMode"]')?.getAttribute('content');
if (!isDebugMode || isDebugMode === 'false') {
  console.error = function () {};
}
