import {LinkElement, Medium, Section} from './multi-stage-interfaces';
import {dia, linkTools, shapes, util} from '@joint/core';
import PortGroup = dia.Element.PortGroup;
import CellViewGeneric = dia.CellViewGeneric;
import Cell = dia.Cell;
import * as constants from './multi-stage-constants';

export function createSection(section: Section, leftPorts: PortGroup, rightPorts: PortGroup, paperHeight: number, _paperWidth: number) {
    const rect = new shapes.standard.Rectangle({
        markup: [
            {
                tagName: 'rect',
                selector: 'body'
            },
            {
                tagName: 'text',
                selector: 'label'
            },
            {
                tagName: 'text',
                selector: 'hotInTemp'
            },
            {
                tagName: 'text',
                selector: 'coldInTemp'
            },
            {
                tagName: 'text',
                selector: 'hotOutTemp'
            },
            {
                tagName: 'text',
                selector: 'coldOutTemp'
            },
            {
                tagName: 'text',
                selector: 'tempUnitTop'
            },
            {
                tagName: 'text',
                selector: 'tempUnitBottom'
            },
            {
                tagName: 'text',
                selector: 'hotPressureDrop'
            },
            {
                tagName: 'text',
                selector: 'coldPressureDrop'
            },
            {
                tagName: 'text',
                selector: 'hotPasses'
            }
            // {
            //     tagName: 'text',
            //     selector: 'coldPasses'
            // }
        ],

        ports: {
            groups: {
                left: leftPorts,
                right: rightPorts
            }
        }
    });
    rect.prop('id', section.id);
    rect.position(100, paperHeight * 0.3);
    rect.resize(150, 150);
    rect.attr({
        body: {
            fill: '#949494'
        },
        label: {
            text: section.name,
            fill: 'white',
            'font-weight': 'bold'
        },
        hotInTemp: {
            text: Number(section.hotInTemp).toFixed(2),
            fill: 'white',
            fontSize: 11,
            x: 'calc(0.01*h)'
        },
        coldInTemp: {
            text: Number(section.coldInTemp).toFixed(2),
            fill: 'white',
            y: 'calc(0.99*h)',
            x: 'calc(0.01*h)',
            fontSize: 11
        },
        hotOutTemp: {
            text: Number(section.hotOutTemp).toFixed(2),
            fill: 'white',
            x: 'calc(0.73*w)',
            fontSize: 11
        },

        coldOutTemp: {
            text: Number(section.coldOutTemp).toFixed(2),
            fill: 'white',
            x: 'calc(0.73*w)',
            y: 'calc(0.99*h)',
            fontSize: 11
        },

        tempUnitTop: {
            text: '°C',
            fill: 'white',
            x: 'calc(0.45*w)',
            fontSize: 11
        },

        tempUnitBottom: {
            text: '°C',
            fill: 'white',
            x: 'calc(0.45*w)',
            y: 'calc(0.99*h)',
            fontSize: 11
        },

        hotPressureDrop: {
            text: Number(section.hotPressureDrop).toFixed(3) + ' kPa',
            fill: 'white',
            x: 'calc(0.35*w)',
            y: 'calc(0.15*h)',
            fontSize: 11
        },
        coldPressureDrop: {
            text: Number(section.coldPressureDrop).toFixed(3) + ' kPa',
            fill: 'white',
            x: 'calc(0.35*w)',
            y: 'calc(0.65*h)',
            fontSize: 11
        },

        hotPasses: {
            text: section.hotPasses + ' / ' + section.hotGapsPerPass,
            fill: 'white',
            x: 'calc(0.42*w)',
            y: 'calc(0.25*h)',
            fontSize: 11
        }

        // coldPasses: {
        //     text: section.coldPasses + ' / ' + section.coldGapsPerPass,
        //     fill: 'white',
        //     x: 'calc(0.42*w)',
        //     y: 'calc(0.75*h)',
        //     fontSize: 11
        // }
    });
    return rect;
}

export function createSectionNew(section: Section, leftPorts: PortGroup, rightPorts: PortGroup, paperHeight: number, _paperWidth: number) {
    const sectionData: any = sectionForeignObject(section);
    const GraphicSection = shapes.standard.Rectangle.define('phe.SectionObject', {

            attrs: {
                body: {
                    stroke: "#0b6fb0",
                },
                foreignObject: {
                    width: '146',
                    height: '146',
                    x: 2,
                    y: 2,
                }
            },

            ports: {
                groups: {
                    left: leftPorts,
                    right: rightPorts,
                },
            },

        },
        {
            markup: sectionData
        });

    let sect: dia.Element = new GraphicSection();

    sect.prop("internalType", "section");
    sect.prop('id', section.id);
    sect.prop('internalId', section.id)
    sect.position(100, paperHeight * 0.3);
    sect.resize(150, 150);

    return sect;
}

export function createMedium(medium: Medium, port: PortGroup) {
    const rect = new shapes.standard.Rectangle({
        markup: [
            {
                tagName: 'rect',
                selector: 'body'
            },
            {
                tagName: 'text',
                selector: 'label'
            },
            {
                tagName: 'text',
                selector: 'mediumName'
            },
            {
                tagName: 'text',
                selector: 'volumeFlow'
            },
            {
                tagName: 'text',
                selector: 'temp'
            },
            {
                tagName: 'text',
                selector: 'operatingPressure'
            },
            {
                tagName: 'text',
                selector: 'label6'
            },
            {
                tagName: 'text',
                selector: 'label7'
            }
        ],

        ports: {
            groups: {
                left: port,
                right: port
            }
        }
    });
    rect.prop('id', medium.id);
    rect.prop('internalId', medium.id)
    rect.position(100, 100);
    rect.resize(120, 100);
    rect.attr({
        body: {
            fill: '#949494'
        },
        mediumName: {
            text: medium.name,
            fill: 'white',
            'ref-y': 'calc(0.01*h)',
            'font-weight': 'bold',
            fontSize: 11,
            textWrap: {}
        },
        volumeFlow: {
            text: Number(medium.volumeFlow).toFixed(2) + ' m³/h',
            fill: 'white',
            y: 'calc(0.40*h)',
            fontSize: 11
        },
        temp: {
            text: Number(medium.temp).toFixed(2) + ' °C',
            fill: 'white',
            y: 'calc(0.55*h)',
            fontSize: 11
        },

        operatingPressure: {
            text: Number(medium.operatingPressure).toFixed(2) + ' barg',
            fill: 'white',
            y: 'calc(0.70*h)',
            fontSize: 11
        }

        /*  label6: {
                  text: 'CIP: 13.68 m²/h',
                  fill: 'white',
                  y: 'calc(0.85*h)',
                  fontSize: 11
              },

              label7: {
                  text: 'CIP 142.000 kPa',
                  fill: 'white',
                  y: 'calc(0.98*h)',
                  fontSize: 11
              }
             */
    });
    return rect;
}

export function createMediumNew(medium: Medium, portLeft: PortGroup, portRight: PortGroup) {
    const fluidData: any = fluidForeignObject(medium);
    const Fluid = shapes.standard.Rectangle.define('phe.FluidObject', {

            attrs: {
                body: {
                    stroke: "#0b6fb0",
                },
                foreignObject: {
                    width: '116',
                    height: '116',
                    x: 2,
                    y: 2,
                }
            },

            ports: {
                groups: {
                    left: portLeft,
                    right: portRight,
                },
            },

        },
        {
            markup: fluidData
        });

    let fluid: dia.Element = new Fluid();

    fluid.prop("internalType", "medium");
    fluid.prop('id', medium.id);
    fluid.prop("internalId", medium.id);
    fluid.position(100, 100);
    fluid.resize(120, 120);

    return fluid;
}

export function createLinkElement(linkEl: any, leftPorts: PortGroup, rightPorts: PortGroup) {


    const linkElData: any = linkElementForeignObject(linkEl);
    let linkHeight = 60;
    linkHeight = linkEl.sectionGroup ? linkHeight + 10 : linkHeight;
    linkHeight = linkEl.passThrough ? linkHeight : linkHeight + 50;

    const LinkEl = shapes.standard.Rectangle.define('phe.LinkObject', {

            attrs: {
                body: {
                    stroke: "#0b6fb0",
                },
                foreignObject: {
                    width: '96',
                    height: `${linkHeight - 4}`,
                    x: 2,
                    y: 2,
                }
            },

            ports: {
                groups: {
                    left: leftPorts,
                    right: rightPorts,
                },
            },

        },


        {
            markup: linkElData
        });

    let link: dia.Element = new LinkEl();

    link.prop("internalType", "linkElement");
    link.addPort({
        id: linkEl.portList[0].id,
        group: "left",
        attrs: {
            label: {text: "F1"},
            portBody: {fill: 'black'}
        }
    });

    link.addPort({
        id: linkEl.portList[1].id,
        group: "right",
        attrs: {
            label: {text: "F2"},
            portBody: {fill: 'black'}
        }
    });

    link.resize(100, linkHeight);

    return link;

}

export function createPort(text: string, id: string, portPosition: string, xValue: number): object {
    return {
        id: id,
        position: {
            name: portPosition
        },
        label: {
            position: {
                name: portPosition
            },
            markup: [
                {
                    tagName: 'text',
                    selector: 'label'
                }
            ]
        },
        attrs: {
            portBody: {
                magnet: true,
                width: 12,
                height: 12,
                x: xValue,
                fill: '#03071E'
            },
            label: {
                text: text,
                fontSize: 11
            }
        },
        markup: [
            {
                tagName: 'rect',
                selector: 'portBody'
            }
        ]
    };
}

export function createButton(): shapes.standard.Rectangle {
    const rect = new shapes.standard.Rectangle();
    rect.prop('id', 3);
    rect.position(100, 200);
    rect.resize(100, 30);

    rect.attr({
        body: {
            fill: '#add8e6'
        },
        label: {
            text: 'Update Data',
            fill: 'white'
        }
    });
    return rect;
}

export function showLinkTools(linkView: CellViewGeneric<Cell>) {
    const tools = new dia.ToolsView({
        tools: [
            new linkTools.Remove({
                distance: '50%',
                markup: [
                    {
                        tagName: 'circle',
                        selector: 'button',
                        attributes: {
                            r: 7,
                            fill: '#f6f6f6',
                            stroke: '#ff5148',
                            'stroke-width': 2,
                            cursor: 'pointer'
                        }
                    },
                    {
                        tagName: 'path',
                        selector: 'icon',
                        attributes: {
                            d: 'M -3 -3 3 3 M -3 3 3 -3',
                            fill: 'none',
                            stroke: '#ff5148',
                            'stroke-width': 2,
                            'pointer-events': 'none'
                        }
                    }
                ]
            })
        ]
    });
    linkView.addTools(tools);
}

export function createLeftPorts(): dia.Element.PortGroup {
    return {
        position: {
            name: 'left',
            args: {}
        },
        attrs: {
            portBody: {
                magnet: true,
                width: 12,
                height: 12,
                x: -12,
                fill: '#03071E'
            }
        },
        label: {
            position: {
                name: 'left',
                args: {y: 6}
            },
            markup: [
                {
                    tagName: 'text',
                    selector: 'label',
                    className: 'label-text'
                }
            ]
        },
        markup: [
            {
                tagName: 'rect',
                selector: 'portBody'
            }
        ]
    };
}

export function createRightPorts(): dia.Element.PortGroup {
    return {
        position: {
            name: 'right',
            args: {}
        },
        attrs: {
            portBody: {
                magnet: true,
                width: 12,
                height: 12,
                x: 0,
                fill: '#03071E'
            }
        },
        label: {
            position: {
                name: 'right',
                args: {y: 6}
            },
            markup: [
                {
                    tagName: 'text',
                    selector: 'label',
                    className: 'label-text'
                }
            ]
        },
        markup: [
            {
                tagName: 'rect',
                selector: 'portBody'
            }
        ]
    };
}

export function linkElementForeignObject(linkEL: LinkElement) {

    const linkGroupHeader = linkEL.sectionGroup ? linkElementGroupHeader(linkEL.name) : '';
    const linkGroupNonPassThroughParams = linkEL.passThrough ? '' : linkElementNonPassThroughParams(linkEL);

    // The /* xml */ comment is optional.
    // It is used to tell the IDE that the markup is XML.
    return util.svg/* xml */`            
        <rect @selector="body"/>
        <foreignObject @selector="foreignObject">
            <div
                xmlns="http://www.w3.org/1999/xhtml"
                style="background-color: #949494; height: 100%;">
                    ${linkGroupHeader}
                <div style="font-size: 70%;" class="text-center">
                    ${constants.icon_left_arrow}
                </div>
                <div style="font-size: 70%;" class="text-center">
                    ${linkEL.name}
                </div>
                    ${linkGroupNonPassThroughParams}
            </div>
        </foreignObject>
        `
}

function linkElementGroupHeader(name: string) {
    return `
        <div style="font-size: 70%;" class="text-center border-bottom">
            ${name}
        </div> 
    `
}

function linkElementNonPassThroughParams(linkEl: LinkElement) {
    return `
        <div style="font-size: 70%; background-color: #b7b7b7; height: inherit" class="border-top">
            <div>
                ${linkEl.mediumName}
            </div>
            <div>
                ${Number(linkEl.volumeFlow).toFixed(2)}
            </div>
            <div>
                ${Number(linkEl.temperature).toFixed(2)}
            </div>
            <div>
                ${Number(linkEl.pressure).toFixed(2)}
            </div>
        </div> 
    `
}

export function fluidForeignObject(fluid: Medium) {

    // The /* xml */ comment is optional.
    // It is used to tell the IDE that the markup is XML.
    return util.svg/* xml */`            
        <rect @selector="body"/>
        <foreignObject @selector="foreignObject">
            <div
                xmlns="http://www.w3.org/1999/xhtml"
                style="background-color: #949494; height: 100%;">
                <div style="font-size: 70%;" class="text-center border-bottom">
                    <div>
                        ${constants.icon_left_arrow}
                    </div>
                    <div>
                        ${fluid.name}
                    </div>
                </div>
                <div style="font-size: 65%; padding-left: 2px;">            
                    <div>                 
                    ${Number(fluid.volumeFlow).toFixed(2)} m³/h
                    </div>
                    <div>                 
                    ${Number(fluid.temp).toFixed(2)} °C
                    </div>
                    <div>
                     ${Number(fluid.operatingPressure).toFixed(2)} barg
                    </div>
                </div>
            </div>
        </foreignObject>
        `
}

export function sectionForeignObject(section: Section) {
    // The /* xml */ comment is optional.
    // It is used to tell the IDE that the markup is XML.
    return util.svg/* xml */`            
        <rect @selector="body"/>
        <foreignObject @selector="foreignObject">
            <div
                xmlns="http://www.w3.org/1999/xhtml"
                style="background-color: #949494; height: 100%;">
                <div style="font-size: 70%; height: 33.3%;" class="text-center border-bottom">
                    <div class="row">
                        <div class="col">
                        ${Number(section.hotInTemp).toFixed(2)}
                        </div>
                        <div class="col">
                        °C
                        </div>
                        <div class="col">
                        ${Number(section.hotOutTemp).toFixed(2)}
                        </div>
                    </div>
                    <div>
                    ${Number(section.hotPressureDrop).toFixed(3)} kpa
                    </div>
                    <div>
                    ${section.hotPasses + ' x ' + section.hotGapsPerPass}
                    </div>
                </div>
                <div style="font-size: 75%; background-color: ${section.color}; height: 33.3%;" class="text-center border-bottom">
                <div>
                ${section.name}
                </div>
                <div>
                ${section.calculationOrderNumber} / ${section.constructionNumber}
                </div>
                </div>
                <div style="font-size: 70%; height: 33.3%;" class="text-center">
                    <div class="row">
                        <div class="col">
                        ${Number(section.coldInTemp).toFixed(2)}
                        </div>
                        <div class="col">
                        °C
                        </div>
                        <div class="col">
                        ${Number(section.coldOutTemp).toFixed(2)}
                        </div>
                    </div>
                    <div>
                    ${Number(section.coldPressureDrop).toFixed(3)} kpa
                    </div>
                    <div>
                    ${section.coldPasses + ' x ' + section.coldGapsPerPass}
                    </div>
                </div>
            </div>
        </foreignObject>
        `
}
